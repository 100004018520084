import type { NotificationGroups } from '@/modules/Notifications/store'

export enum NotificationNames {
  unknown = 'unknown',
  // Alerting
  alertingHwMotion = 'alerting:al-hw-motion',
  alertingSwMotion = 'alerting:al-sw-motion',
  alertingHwAudio = 'alerting:al-hw-audio',
  alertingSwAudio = 'alerting:al-sw-audio',
  alertingHwTampering = 'alerting:al-hw-tampering',
  alertingSwTampering = 'alerting:al-sw-tampering',
  alertingHwFence = 'alerting:al-hw-fence',
  alertingSwFence = 'alerting:al-sw-fence',
  alertingHwCrossLine = 'alerting:al-hw-cross-line',
  alertingSwCrossLine = 'alerting:al-sw-cross-line',
  alertingSwCrossLineIn = 'alerting:al-sw-cross-line-in',
  alertingSwCrossLineOut = 'alerting:al-sw-cross-line-out',

  // Authentication
  authenticationUpdatePhone = 'authentication:au-update-phone',
  authenticationChangePhoneAttempt = 'authentication:au-change-phone-attempt',
  authenticationUpdateEmail = 'authentication:au-update-email',
  authenticationPasswordUpdated = 'authentication:au-password-updated',
  authenticationSuspiciousActivity = 'authentication:au-suspicious-activity',
  authenticationSuccessfulRegister = 'authentication:au-successful-register',
  authenticationSuccessfulLogin = 'authentication:au-successful-login',
  authenticationActivateMFA = 'authentication:au-activate-mfa',

  // Camera Health
  cameraHealthHealthy = 'camera-health:ch-healthy',
  cameraHealthUnhealthy = 'camera-health:ch-unhealthy',

  // Camera
  cameraCreationFailed = 'camera:cm-creation-failed',
  cameraRecordingPaused = 'camera:cm-recording-paused',
  cameraRecordingResumed = 'camera:cm-recording-resumed',

  // Export
  exportExportFailed = 'export:em-export-failed',
  exportExportFinished = 'export:em-export-finished',

  // Payment
  paymentActionRequired = 'payment:p-action-required',
  paymentRenewalPaused = 'payment:p-renewal-paused',
  paymentTrialEnding = 'payment:p-trial-ending',
  paymentTrialEnded = 'payment:p-trial-ended',
  paymentInvoicePaid = 'payment:p-invoice-paid',
  paymentPmExpiring = 'payment:p-pm-expiring',

  // Permission
  permissionSuspendTeam = 'permission:pc-suspend-team',

  // User Event
  userEventSessionActivated = 'user-event:ueb-session-activated',

  // Workspace
  workspaceInviteUser = 'workspace:ws-invite-user',
  workspaceInviteAccepted = 'workspace:ws-invite-accepted',
  workspaceInviteCancelled = 'workspace:ws-invite-cancelled',
  workspaceUserRemoved = 'workspace:ws-user-removed',
  workspaceUserSuspended = 'workspace:ws-user-suspended'
}

export enum NotificationTypes {
  email = 'EMAIL',
  sms = 'SMS',
  database = 'DATABASE',
  firebase = 'FIREBASE'
}

export interface NotificationSetting {
  names?: Array<NotificationNames>
}

export interface NotificationUserSettingsResponse {
  id: string
  userId: string
  notificationName: NotificationNames
  notificationType: NotificationTypes
  interval?: number
}

export interface NotificationUserSettings {
  notificationName: Array<NotificationNames>
  notificationType: NotificationTypes
  interval?: NotificationUpdateInterval
  type: number | boolean
  direct?: boolean
  muteEnabled: boolean
  group: NotificationGroups
  inactiveNotificationType?: NotificationTypes
  notificationTypes?: NotificationTypes
}
export interface NotifierPayload {
  interval?: NotificationUpdateInterval
  group: NotificationGroups
  notificationTypes: NotificationTypes
  notificationType: NotificationTypes
  type: NotificationUpdateInterval
}

export interface NotifierPayloadMuteInterval {
  interval?: NotificationUpdateInterval
  group: NotificationGroups
  inactiveNotificationType: Array<NotificationTypes>
  type: Number
  notificationName: Array<NotificationNames>
  notificationTypes: Array<NotificationTypes>
}

export interface NotificationMuteIntervalSettings {
  minutes: number
  types: NotificationTypes[]
}

export enum NotificationUpdateInterval {
  normal,
  muteInterval
}

export interface NotificationUpdateBody extends NotificationUserSettings {
  interval?: NotificationUpdateInterval
  type: number | boolean
  direct?: boolean
}

export enum FilterGroups {
  cameraHealthAndTampering = 'camera-and-tampering',
  aIAndVideoIntelligenceSuit = 'ai-events',
  billingAndInvoicing = 'payment',
  securityAndAuthentication = 'security-and-authentication'
}

export interface FilterGroupDto {
  always: Array<NotificationTypes>

  never: Array<NotificationTypes>

  interval: number

  group: FilterGroups
}
