const groupSettings = {
  titles: {
    aIAndVideoIntelligenceSuit: 'AI & Video Intelligence Suite',
    securityAndAuthentication: 'Other',
    billingAndInvoicing: 'Billing & Invoicing',
    cameraHealthAndTampering: 'Camera Health & Tampering'
  },
  switchTexts: {
    on: 'Always',
    off: 'Never'
  },
  FIREBASE: 'In-App Notifications',
  EMAIL: 'Email Notifications',
  SMS: 'SMS Alerts'
}
export default {
  routeName: 'notifications',
  subTabTitle: 'Inbox',
  myInbox: 'My Inbox',
  title: 'Notifications',
  notifications: 'Notifications',
  notification: 'Notification',
  clickhere: 'click here.',
  LandonSecurity: 'Show me',
  Clips: 'Show me',
  LogIn: 'Show me',
  UserDetails: 'Show me',
  PeopleLanding: 'Show me',
  RoleDetails: 'Show me',
  camera: 'Show me',
  WorkspaceBillingLanding: 'Show me',
  emailWillSend: 'All notifications will be sent to',
  new: 'New',
  muteInterval: 'Cooldown Notifications?',
  inAppNotificationHelpTooltips:
    'Stay connected and never miss a beat! Enable this to receive all your in-app, desktop, and mobile push notifications—it’s the ultimate way to stay in the loop',
  muteIntervalTooltips:
    "Notification cooldown adds a brief delay to confirm if a threat is real before sending an alert. If the event continues, you'll still be notified—perfect for low-bandwidth or unstable connections.",
  groupSettings,
  dropdown: {
    /*
    Using this enum for translation
    enum ScheduleMode {
      afterHours,
      inHours,
      all
    }
    */
    labels: {
      2: 'All Day, Everyday',
      1: 'Business hours',
      0: 'After hours'
    },
    descriptions: {
      2: 'Ideal for environments demanding uninterrupted 24/7 monitoring and proactive alerts, ensuring constant vigilance and security.',
      1: 'Designed for industries that necessitate proactive AI alerts during operational hours, facilitating critical activities such as monitoring safety zones.',
      0: 'Ideal for businesses that want to be notified of motion, intrusions, analytics and theft after operating hours when no one is on-site.'
    },
    footerButton: 'My hours',
    tooltip: 'My preferred schedule\nfor receiving proactive\nalerts'
  },
  description: {
    title: 'My Inbox',
    text: "Everything you've snoozed, all in one place."
  },
  actions: {
    goToInbox: 'My Inbox',
    clear: 'Clear',
    verifyNewEmail: 'Verify New Email'
  },
  fastFilters: {
    all: 'All',
    ...groupSettings.titles
  },
  emptyDrawer: {
    dearUser: 'hey {fullname}',
    content:
      "Inbox Zero! You've cleared all your important notifications. In the meantime, learn about our business advocacy initiative.",
    bringFriendEarnCash: 'Bring a Friend, Earn Cash 💸'
  },
  errorMessage: {
    defaultError: `hey, we've noticed a connection issue. If the problem persists, contact us at +1 (800) 288-9192 or sales{'@'}arcadian.ai. We're here to help!`
  }
}
