export default {
  bridge: {
    connectCamera: 'Connect Cameras',
    arcadianCamera: {
      title: "Fantastique! Let's connect your cameras.",
      subtitle:
        'ArcadianAI is how security monitoring should be: Simple, Affordable & Proactive. Scan you QR code to begin monitoring. ',
      dontHave: 'Don’t have cameras? ',
      infoTitle: 'The Cloud Subscription ',
      infoContent:
        'To fully utilize your AI Security cameras, a monthly or yearly subscription is required. This subscription grants you comprehensive access to all ArcadianAI streaming and monitoring services, unlimited AI heatmap plans, and options for 30, 60, or 90 days of cloud storage. Billing starts as soon as you connect your cameras to the platform.',
      camera: 'ArcadianAI'
    },
    drawer: {
      title: 'Let’s bridge the gap!',
      subtitle:
        'Keep your gear. Simply connect the Arcadian Bridge cloud connector to your modem and we will take care of the rest for you. ',
      dontHave: 'Don’t have one? ',
      shop: 'Shop',
      byQRCode: 'QR',
      noCameraDevice: 'No cameras on this device',
      byActivationCode: 'Code',
      uploadImage: 'Upload',
      setupHelp: 'Setup Help',
      infoTitle: 'Tips on Plug & Play',
      infoContent:
        'The Arcadian Bridge acts as your cloud agent, auto-discovering all cameras on your network with end-to-end encryption. Best part? No footage is ever lost. Even if the internet drops, the Arcadian Bridge stores your footage on a local SD card until it’s safely transferred to the cloud.',
      stayHere: 'Stay here. . . ',
      stayHereContent:
        "We're swiftly connecting your device to the cloud. Please make sure your bridge is powered on and within close proximity. This could take a few minutes.",
      areYouSure: 'Are you sure?',
      areYouSureContent:
        "Auto-discovery of your devices is in progress. Terminate this session by closing the window or clicking 'Cancel', and you'll lose all your work. Want to continue? Select 'Cancel Pairing' again.",
      foundYourBridge: 'Found your bridge!',
      foundYourBridgeContent:
        "We've detected {data}  nearby. To streamline their connection, please share their usernames and passwords with us.",
      cameraUsername: 'Camera Username',
      cameraPassword: 'Camera Password',
      discoverMore: 'Discover More',
      connectCameras: 'Connect Cameras',
      skipPassword: 'Skip, Input Passwords for Each Camera',
      updateAlertTitle: 'Why am I seeing this?',
      updateAlertContent:
        "Regular software maintenance updates are vital to ensure your bridge and connected cameras operate smoothly. We've detected that the device in your possession is not compatible with our latest version. Rest assured, the necessary update will only require a few minutes of your time.",

      notDetectedAlertTitle: 'Couldn’t locate your bridge',
      notDetectedContent:
        "It seems like your device may have had a momentary lapse. Let's get it back on track! Please ensure that your bridge is nearby and powered on",
      installUpdate: 'Install Latest Software',
      releaseNote: 'Release Note ( {data} )',
      confirmCancel: 'Cancel Pairing'
    },
    profile: {
      hardwareSpecs: 'Hardware Specs',
      deviceName: 'Device Name',
      deviceLocation: 'Device Location (Physical Address)',
      addTags: 'Add Tags',
      model: 'Model',
      brand: 'Brand',
      hardwareID: 'Hardware ID',
      software: 'Software',
      version: 'Version No.',
      connectedCameras: 'Connected Cameras'
    },
    status: {
      '0': 'Inactivate',
      '1': 'Connected',
      '2': 'Connecting...',
      '3': 'Disconnected',
      '4': 'Pending Update',
      needUpdate: 'Pending Update',
      installing: 'Installing an Update...'
    },
    menu: {
      edit: 'Edit Setup',
      editBulk: 'Edit Bulk',
      autoDiscover: 'Auto-discover',
      forget: 'Forget Device',
      update: 'Update Available',
      updated: 'Up to date (v.{data})'
    },
    errorMessage: {
      notfound: 'Unrecognized QR Code. Try again or get in touch with our support team.',
      expiredTime: 'Expired Time',
      expiredTimeErrorMessage: "Ops we can't Detective your Bridge, please try again",
      cameraAddSuccess: 'Camera connected successfully',
      successfulDelete: 'Bridge removed successfully',
      successUpdate: 'Bridge Updated successfully',
      cameraConnectFail: 'No cameras added',
      internalservererror: 'Server Error 500',
      dontHaveActiveBridge: "You don't Have an Active bridge. Please go to the previous step",
      'code:isrequired': 'Error: QR code Invalid. Upload a clear image of your QR code.'
    },
    discoveryLoader: 'We are doing auto-discovery, please wait ...',
    delete: {
      title:
        'Ready to wipe out {name} and its entire cloud collection (cameras, recordings, clips, and analytics)? Type in your name to make it official.',
      description:
        "By selecting 'Delete Device', I acknowledge that I am initiating an irreversible action. This will permanently disconnect Arcadian Orbit Inc.'s services for my device, leading to the immediate and irretrievable loss of all recorded footage and all associated hardware data.",
      deleteButton: 'Delete',
      inputLabel: 'Type {name}'
    }
  }
}
