import { ResourceTypes } from '@/lib/api'
import { Actions, usePermissionsStore, type MatrixAuthorities } from '..'
import { groupBy } from 'lodash'
import type { Dictionary } from 'lodash'
import { useWorkspaceStore } from '@/modules/Workspace/store/useWorkspaceStore'
import { useEventBus } from '@/utils/event-bus/EventBus'
import { usePaymentStore } from '@/stores/payment/usePaymentStore'

export class MatrixDifferChecker {
  private oldMatrix: MatrixAuthorities[] = []
  private newMatrix: MatrixAuthorities[] = []

  constructor() {}

  setOldMatrix(oldMatrix: MatrixAuthorities[]) {
    this.oldMatrix = oldMatrix
  }

  setNewMatrix(newMatrix: MatrixAuthorities[]) {
    this.newMatrix = newMatrix
  }

  async checkMatrixes() {
    if (this.oldMatrix.length > 1) {
      await this.checkAddedAuthority()
      await this.checkRemovedAuthority()
      await this.checkLoadPayment()
    }
  }

  async checkRemovedAuthority(): Promise<void> {
    const differences = groupBy(
      this.oldMatrix.filter(
        (oldAuthority) =>
          !this.newMatrix.some((newAuthority) => {
            return this.compareFunction(oldAuthority, newAuthority)
          })
      ),
      ({ resourceId }) => resourceId
    )
    if (Object.keys(differences).length > 0) {
      await this.removeResource(differences)
    }
  }

  async checkAddedAuthority(): Promise<void> {
    const differences = groupBy(
      this.newMatrix.filter(
        (newAuthority) =>
          !this.oldMatrix.some((oldAuthority) => {
            return this.compareFunction(newAuthority, oldAuthority)
          })
      ),
      ({ resourceId }) => resourceId
    )
    if (Object.keys(differences).length > 0) {
      await this.addResource(differences)
    }
  }

  private async removeResource(difference: Dictionary<MatrixAuthorities[]>): Promise<void> {
    for (const resourceId in difference) {
      const { currentWorkspace } = useWorkspaceStore()
      const type = difference[resourceId][0].type
      switch (type) {
        case ResourceTypes.Camera:
          useEventBus().emit('camera:remove', resourceId)
          break
        case ResourceTypes.Workspace:
          if (currentWorkspace?.id) usePermissionsStore().getUsers(currentWorkspace?.id)
          break
        case ResourceTypes.Bridge:
          useEventBus().emit('bridge:remove', resourceId)
          break
        case ResourceTypes.BridgeCamera:
        case ResourceTypes.CameraGroup:
        case ResourceTypes.Map:
          break
      }
    }
  }
  private async addResource(difference: Dictionary<MatrixAuthorities[]>): Promise<void> {
    for (const resourceId in difference) {
      const { currentWorkspace } = useWorkspaceStore()
      const type = difference[resourceId][0].type
      switch (type) {
        case ResourceTypes.Camera:
          useEventBus().emit('camera:add', resourceId)
          break
        case ResourceTypes.Workspace:
          if (currentWorkspace?.id) usePermissionsStore().getUsers(currentWorkspace?.id)
          break
        case ResourceTypes.Bridge:
          useEventBus().emit('bridge:add', resourceId)
          break
        case ResourceTypes.BridgeCamera:
        case ResourceTypes.CameraGroup:
        case ResourceTypes.Map:
          break
      }
    }
  }

  private compareFunction(a: MatrixAuthorities, b: MatrixAuthorities) {
    return a.resourceId === b.resourceId && a.type === b.type
  }

  private async checkLoadPayment() {
    const oldHave = this.oldMatrix.some((permission) => {
      return permission.key === Actions.workspaceManagePayment
    })
    const newHave = this.newMatrix.some((permission) => {
      return permission.key === Actions.workspaceManagePayment
    })
    if (!oldHave && newHave) await usePaymentStore().loadPaymentInformation(true)
  }
}
