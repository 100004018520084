export enum AnalyticEventTypes {
  None = 'none', // 0
  HwSensor = 'hw-sensor', // 1
  HwTampering = 'hw-tampering', // 2
  HwAudio = 'hw-audio', // 3
  HwMotion = 'hw-motion', // 4
  HwBorderCrossing = 'hw-border-crossing', // 5
  HwAppearance = 'hw-appearance', // 6
  HwDisappearance = 'hw-disappearance', // 7
  HwCapacity = 'hw-capacity', // 8
  SwMotion = 'sw-motion', // 9
  SwFence = 'sw-fence', // 10
  SwLeftMissed = 'sw-left_missed' // 11
}

export enum AnalyticDetailedTyped {
  unknown = -1,
  // hardware analytic
  motionHardware = 0, // AnalyticType.MotionHardware
  tamperingHardware = 50, // AnalyticType.TamperingHardware
  audioSuddenRaiseHardware = 100,
  audioSuddenFallRaiseHardware = 101,
  audioSuddenChangeHardware = 102, // AnalyticType.AudioDetectionHardware
  audioThresholdHardware = 103,
  crossLineHardware = 150, // AnalyticType.CrossLineHardware
  crossLineBothWayHardware = 150,
  crossLineAtoBWayHardware = 151,
  crossLineBtoAWayHardware = 152,

  // hardware AI
  // ...

  // software analytic
  motionSoftware = 10_000, // AnalyticType.Motion
  crossLineSoftware = 10_100, // AnalyticType.Fence
  fenceSoftware = 10_101, // AnalyticType.Fence
  missedObject = 10_200, // AnalyticType.LeftAndMissed
  leftObject = 10_201 // AnalyticType.LeftAndMissed

  // software AI
  // ...
}

export function convertEventType(type: number): AnalyticEventTypes {
  switch (type) {
    case 0:
      return AnalyticEventTypes.None
    case 1:
      return AnalyticEventTypes.HwSensor
    case 2:
      return AnalyticEventTypes.HwTampering
    case 3:
      return AnalyticEventTypes.HwAudio
    case 4:
      return AnalyticEventTypes.HwMotion
    case 5:
      return AnalyticEventTypes.HwBorderCrossing
    case 6:
      return AnalyticEventTypes.HwAppearance
    case 7:
      return AnalyticEventTypes.HwDisappearance
    case 8:
      return AnalyticEventTypes.HwCapacity
    case 9:
      return AnalyticEventTypes.SwMotion
    case 10:
      return AnalyticEventTypes.SwFence
    case 11:
      return AnalyticEventTypes.SwLeftMissed
    default:
      return AnalyticEventTypes.None
  }
}

export interface FindEventDto {
  cameraId: string

  type: AnalyticEventTypes

  index?: string

  startTime: string

  endTime: string
}

export interface LatestEventDetail {
  type: AnalyticEventTypes
  index: string
  events: Array<[number, number]>
}

export interface EventResult {
  events: Array<Array<number>>
  limit: number
}
