import type { InvitationType } from '@/modules/Permissions/components/interfaces'

export enum Languages {
  afar = 'aa',
  abkhazian = 'ab',
  avestan = 'ae',
  afrikaans = 'af',
  akan = 'ak',
  amharic = 'am',
  aragonese = 'an',
  arabic = 'ar',
  assamese = 'as',
  avaric = 'av',
  aymara = 'ay',
  azerbaijani = 'az',
  bashkir = 'ba',
  belarusian = 'be',
  bulgarian = 'bg',
  bihari = 'bh',
  bislama = 'bi',
  bambara = 'bm',
  bengali = 'bn',
  tibetan = 'bo',
  breton = 'br',
  bosnian = 'bs',
  catalan = 'ca',
  chechen = 'ce',
  chamorro = 'ch',
  corsican = 'co',
  cree = 'cr',
  czech = 'cs',
  church = 'cu',
  chuvash = 'cv',
  welsh = 'cy',
  danish = 'da',
  german = 'de',
  divehi = 'dv',
  dzongkha = 'dz',
  ewe = 'ee',
  greek = 'el',
  english = 'en',
  esperanto = 'eo',
  spanish = 'es',
  estonian = 'et',
  basque = 'eu',
  persian = 'fa',
  fulah = 'ff',
  finnish = 'fi',
  fijian = 'fj',
  faroese = 'fo',
  french = 'fr',
  western = 'fy',
  irish = 'ga',
  gaelic = 'gd',
  galician = 'gl',
  guarani = 'gn',
  gujarati = 'gu',
  manx = 'gv',
  hausa = 'ha',
  hebrew = 'he',
  hindi = 'hi',
  hiri = 'ho',
  croatian = 'hr',
  haitian = 'ht',
  hungarian = 'hu',
  armenian = 'hy',
  herero = 'hz',
  interlingua = 'ia',
  indonesian = 'id',
  interlingue = 'ie',
  igbo = 'ig',
  sichuan = 'ii',
  inupiaq = 'ik',
  ido = 'io',
  icelandic = 'is',
  italian = 'it',
  inuktitut = 'iu',
  japanese = 'ja',
  javanese = 'jv',
  georgian = 'ka',
  kongo = 'kg',
  kikuyu = 'ki',
  kuanyama = 'kj',
  kazakh = 'kk',
  kalaallisut = 'kl',
  central = 'km',
  kannada = 'kn',
  korean = 'ko',
  kanuri = 'kr',
  kashmiri = 'ks',
  kurdish = 'ku',
  komi = 'kv',
  cornish = 'kw',
  kirghiz = 'ky',
  latin = 'la',
  luxembourgish = 'lb',
  ganda = 'lg',
  limburgan = 'li',
  lingala = 'ln',
  lao = 'lo',
  lithuanian = 'lt',
  lubaKatanga = 'lu',
  latvian = 'lv',
  malagasy = 'mg',
  marshallese = 'mh',
  maori = 'mi',
  macedonian = 'mk',
  malayalam = 'ml',
  mongolian = 'mn',
  marathi = 'mr',
  malay = 'ms',
  maltese = 'mt',
  burmese = 'my',
  nauru = 'na',
  bokmal = 'nb',
  ndebeleNorth = 'nd',
  nepali = 'ne',
  ndonga = 'ng',
  dutch = 'nl',
  norwegianNynorsk = 'nn',
  norwegian = 'no',
  ndebele = 'nr',
  navajo = 'nv',
  chichewa = 'ny',
  occitan = 'oc',
  ojibwa = 'oj',
  oromo = 'om',
  oriya = 'or',
  ossetian = 'os',
  panjabi = 'pa',
  pali = 'pi',
  polish = 'pl',
  pushto = 'ps',
  portuguese = 'pt',
  quechua = 'qu',
  romansh = 'rm',
  rundi = 'rn',
  romanian = 'ro',
  russian = 'ru',
  kinyarwanda = 'rw',
  sanskrit = 'sa',
  sardinian = 'sc',
  sindhi = 'sd',
  northern = 'se',
  sango = 'sg',
  sinhala = 'si',
  slovak = 'sk',
  slovenian = 'sl',
  samoan = 'sm',
  shona = 'sn',
  somali = 'so',
  albanian = 'sq',
  serbian = 'sr',
  swati = 'ss',
  sotho = 'st',
  sundanese = 'su',
  swedish = 'sv',
  swahili = 'sw',
  tamil = 'ta',
  telugu = 'te',
  tajik = 'tg',
  thai = 'th',
  tigrinya = 'ti',
  turkmen = 'tk',
  tagalog = 'tl',
  tswana = 'tn',
  tonga = 'to',
  turkish = 'tr',
  tsonga = 'ts',
  tatar = 'tt',
  twi = 'tw',
  tahitian = 'ty',
  uighur = 'ug',
  ukrainian = 'uk',
  urdu = 'ur',
  uzbek = 'uz',
  venda = 've',
  vietnamese = 'vi',
  volapük = 'vo',
  walloon = 'wa',
  wolof = 'wo',
  xhosa = 'xh',
  yiddish = 'yi',
  yoruba = 'yo',
  zhuang = 'za',
  chinese = 'zh',
  zulu = 'zu'
}
export interface CreateWorkspace {
  name?: string
  description?: string // optional
  language?: Languages // optional
  welcomeMessage?: string // optional
  operationHours?: operationHours[]
  industries?: string[] | null
}
export interface payloadRemovePendingUser {
  phone?: string
  email?: string
}

export interface WorkSpaceCurrent {
  id: string
  name: string
  description: string
  logo: string
  operationHours: operationHours[]
  industries: string[]
  language: string
  welcomeMessage: string
  activeTermsId: string
  ownerId: string
  theme: object
  createdAt: Date
}

export enum UserActivityState {
  online = 'online',
  offline = 'offline',
  idle = 'idle'
}

export interface WorkSpaceUser {
  id: string
  remoteId: string
  customerId?: string
  fullName: string
  has2FA: boolean
  got2FA?: boolean
  phone: string
  email: string
  position?: string
  avatar?: string
  onlineStatus: UserActivityState
}

export enum WorkspaceUsersStatus {
  pending = 'pending',
  joined = 'joined',
  termsRequired = 'termsRequired',
  withdrawn = 'withdrawn',
  notFound = 'notFound'
}

export interface WorkSpaceUserAdvance extends WorkSpaceUser {
  createdAt: string
  status: WorkspaceUsersStatus
  team?: {
    id: string
    name: string
  }
}
export interface inviteUserWorkspace {
  phone?: string
  email?: string
  teamId?: string
  type?: InvitationType | string
}

export interface PendingUsers {
  id: string
  remoteId: string
  customerId: string
  fullName: string
  has2FA: boolean
  got2FA: boolean
  phone: string
  email: string
}

export interface PendingUsersAdvance extends PendingUsers {
  status: WorkspaceUsersStatus
  team?: {
    id: string
    name: string
    lock: boolean
    workspaceId: string
    suspendAt?: Date
    authorities?: Array<number>
    createdAt: Date
    updatedAt?: Date
  }
}

export interface operationHours {
  close?: boolean
  dayName: WeekDaysEnum
  from?: Date
  to?: Date
}
export enum WeekDaysEnum {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export interface WorkspaceStatus {
  id: string
  activePayment: boolean
  locked: boolean
  lockForUser: boolean
}
