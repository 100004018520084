import { VirtualTeamAuthorities } from './Authorities'
import { PermissionManager } from './PermissionManager'

let permissionManager: null | PermissionManager = null

export function usePermissionManager() {
  if (!permissionManager) {
    permissionManager = new PermissionManager()
    return permissionManager
  } else return permissionManager
}

let virtualTeamAuthorities: null | VirtualTeamAuthorities = null

export function useVirtualTeamAuthorities() {
  if (!virtualTeamAuthorities) {
    virtualTeamAuthorities = new VirtualTeamAuthorities()
    return virtualTeamAuthorities
  } else return virtualTeamAuthorities
}

export * from './interface'

export * from './store'

export * from './helpers'
