import { type EventResult, type LatestEventDetail, Service } from '@/lib/api'
import type { FindEventDto } from '@/lib/api/services/ai/analytics/event.interface'

export class AnalyticEventService extends Service {
  public find(request: FindEventDto): Promise<Array<Array<number>>> {
    return this.post<EventResult | Array<Array<number>>>('/api/alert-manager/events', request).then(
      (r) => {
        if (Array.isArray(r)) {
          return r
        } else return r.events
      }
    )
  }

  public latest(cameraId: string): Promise<LatestEventDetail[]> {
    return this.get<LatestEventDetail[]>(`/api/alert-manager/events/${cameraId}/latest`)
  }
  public latestAdvance(cameraId: string, type: string, index: string) {
    return this.post<Array<[number, number]>>(`/api/alert-manager/events/${cameraId}/latest`, {
      type,
      index
    })
  }
}
