import type {
  CreateWorkspace,
  inviteUserWorkspace,
  payloadRemovePendingUser,
  PendingUsers,
  PendingUsersAdvance,
  WorkSpaceCurrent,
  WorkSpaceUser,
  WorkSpaceUserAdvance,
  WorkspaceStatus
} from './workspace.interfaces'
import { Service } from '../../service'

export class WorkspaceService extends Service {
  public create(data: CreateWorkspace) {
    return this.post<WorkSpaceCurrent>(`/api/workspace-manager/workspace`, data)
  }

  public removeCurrentWorkspace() {
    return this.delete<void>(`/api/workspace-manager/workspace`)
  }

  public current() {
    return this.get<WorkSpaceCurrent>(`/api/workspace-manager/workspace/current`)
  }

  public find(id: string) {
    return this.get<WorkSpaceCurrent>(`/api/workspace-manager/workspace/${id}`)
  }
  public findStatus() {
    return this.get<WorkspaceStatus[]>(`/api/workspace-manager/workspace/status`)
  }

  public users(id: string) {
    return this.get<WorkSpaceUser[]>(`/api/workspace-manager/workspace/${id}/users`)
  }

  public pendingUsers(id: string) {
    return this.get<PendingUsers[]>(`/api/workspace-manager/workspace/${id}/users/pending`)
  }

  public advancePendingUsers(id: string) {
    return this.get<PendingUsersAdvance[]>(
      `/api/workspace-manager/workspace/${id}/users/pending/details`
    )
  }

  public advanceUsers(id: string) {
    return this.get<WorkSpaceUserAdvance[]>(`/api/workspace-manager/workspace/${id}/users/details`)
  }

  public advanceUser(userId: string) {
    return this.get<WorkSpaceUserAdvance>(
      `/api/workspace-manager/workspace/users/${userId}/details`
    )
  }

  public invite(id: string, data: inviteUserWorkspace) {
    return this.post(`/api/workspace-manager/workspace/${id}/users`, data)
  }

  public update(id: string, data: CreateWorkspace) {
    return this.patch<WorkSpaceCurrent, CreateWorkspace>(
      `/api/workspace-manager/workspace/${id}`,
      data
    )
  }

  public deleteUser(id: string, userId: string) {
    return this.delete(`/api/workspace-manager/workspace/${id}/users/${userId}/remove`)
  }
  public suspendUser(id: string, userId: string) {
    return this.delete(`/api/workspace-manager/workspace/${id}/users/${userId}`)
  }
  public resendUser(id: string, data: inviteUserWorkspace) {
    return this.post(`/api/workspace-manager/workspace/${id}/users/resend`, data)
  }
  public restoreWithdrawn(id: string, userId: string) {
    return this.patch(`/api/workspace-manager/workspace/${id}/users/${userId}`)
  }
  public removePendingUser(id: string, data: payloadRemovePendingUser) {
    return this.deleteData(`/api/workspace-manager/workspace/${id}/users/pending`, data)
  }
  // *************Avatar******
  public avatarUpdate(data: FormData) {
    return this.patch(`/api/workspace-manager/avatar`, data)
  }
  public avatarRemove() {
    return this.delete(`/api/workspace-manager/avatar`)
  }

  public avatarGet(cameraId: string) {
    return this.adaptor.get(`/api/workspace-manager/avatar/${cameraId}`, {
      responseType: 'blob'
    })
  }
}
