import { watch } from 'vue'
import { PlayerCore } from '@/player/lib/player/player-core'
import { EventTypes } from '@/player/types'
import { throttle } from 'lodash'

export class PlayerWatchers {
  protected lastFrameHasAnalytic = false
  protected loadArchiveMap: () => void
  protected loadAnalytic: () => void
  protected loadStatus: () => void
  constructor(protected readonly core: PlayerCore) {
    // todo add unwatch
    this.cameraWatchers()
    this.loadArchiveMap = throttle(() => {
      // to give server enough time but should replace with websocket event
      this.core.apis.archiveMap()
      this.core.apis.downtimeMap()
    }, 2000)
    this.loadAnalytic = throttle(() => {
      this.core.analyticManager2.onSoftSeek()
    }, 1000)
    this.loadStatus = throttle(() => {
      this.core.apis.detailedStatus()
    }, 15000)
  }

  public cameraWatchers() {
    watch(this.core.detailedStatus, this.core.helpers.calculateStatus.bind(this.core.helpers))
    watch(this.core.size, this.core.helpers.setRenderSizes.bind(this.core.helpers))
    watch(
      [this.core.archiveMap, this.core.downMap],
      this.core.helpers.setArchiveMap.bind(this.core.helpers)
    )
    watch(this.core.bookmarks, this.core.helpers.setBookmark.bind(this.core.helpers))
    watch(
      () => this.core.information.maskData,
      this.core.maskHelper.onMaskUpdate.bind(this.core.maskHelper)
    )
    watch(
      [() => this.core.playback.timelineSize, this.core.size],
      this.core.analyticHelper.watchAnalyticTimelineSize.bind(this.core.analyticHelper),
      { immediate: true }
    )
    window.addEventListener(
      'resize',
      this.core.helpers.setRenderSizes.bind(this.core.helpers),
      false
    )
  }

  public onCaptureEvents(set: Set<EventTypes>, date: number) {
    if (set.has(EventTypes.NoFrame)) {
      // this.core.showInfo('This camera has no footage for this moment.')
    }
    if (set.has(EventTypes.ArchiveMapChanged)) {
      this.loadArchiveMap()
    }
    if (this.core.playback.isLive) {
      if (
        this.core.timeline &&
        this.core.timeline.tampering &&
        this.core.timeline.tampering.cache.follower
      ) {
        this.core.timeline.tampering.cache.follower.capture(set, date)
      }
      if (this.core.timeline && this.core.timeline.analytics) {
        const analytic = this.core.analyticManager2.getActiveAnalytic()
        if (analytic && analytic.frameFollower) {
          analytic.processLiveFrames(set, date)
        }
      }
    }
    if (
      set.has(EventTypes.Connected) ||
      set.has(EventTypes.Disconnected) ||
      set.has(EventTypes.RecordFailed)
    ) {
      this.loadStatus()
    }
  }
}
