import { ref, type Ref } from 'vue'
import type { PlayerCore } from '@/player/lib/player/player-core'
import { FunctionMode, InfoMode, PeriodType } from '@/player/types'
import { type PlayerAnalyticConfig } from '@/player/interfaces'
import { AnalyticEventTypes } from '@/lib/api'
import { timelinePositionConst } from '@/player/lib/playback-timeline/timeline-consts'
import { useEventBus } from '@/utils/event-bus/EventBus'

export class AnalyticHelper {
  public readonly activePeriod: Ref<[number, number] | undefined> = ref()
  protected latestConfig: PlayerAnalyticConfig = {
    enabled: false,
    activeEventType: AnalyticEventTypes.None,
    activeIndex: '',
    isMenuOpen: false
  }
  protected intervalHandle?: ReturnType<typeof setInterval>
  public loading = ref(false)

  get config() {
    return this.core.analyticConfig
  }

  constructor(public readonly core: PlayerCore) {}

  watchAnalyticTimelineSize() {
    if (this.core.playback.timelineSize > PeriodType.quarter || this.core.size.type !== 'large') {
      if (this.config.enabled) {
        this.core.showInfo(
          'To view AI-enabled events, stick to intervals of 15-min or less.',
          InfoMode.expand
        )
      }
      this.disable()
    } else {
      if (this.latestConfig.activeEventType !== AnalyticEventTypes.None) {
        this.enable(this.latestConfig.activeEventType, this.latestConfig.activeIndex)
      }
    }
  }

  disable() {
    this.resetConfig()
    clearInterval(this.intervalHandle)
    this.disableTimeline()
    this.core.analyticManager2.disable()
    this.resetActivePeriod()
  }

  enableByIndex(index: number) {
    const analytic = this.core.analyticManager2.analytics[index]
    if (analytic) {
      const identity = analytic.identity
      return this.enable(identity.type, identity.index)
    }
  }

  async enable(eventType: AnalyticEventTypes, index = '0') {
    if (!this.loading.value && this.isNewState(eventType, index)) {
      this.loading.value = true
      try {
        if (!this.isL1Level()) {
          this.core.playback.timelineSize = PeriodType.singleMinute
        }
        if (this.core.size.type === 'large') {
          await Promise.all([
            this.jumpToLatestEvent(eventType, index),
            this.core.analyticManager2.initWait()
          ])
          this.core.analyticManager2.enable(this.getAnalyticIndex(eventType, index))
          this.updateConfig(eventType, index)
          this.resetInterval()
          this.resetActivePeriod()
          this.activateTimelineL1()
          this.checkAudio(eventType)
        }
      } catch (e) {
        console.error(e)
        this.disable()
        const eventBus = useEventBus()
        eventBus.emit('toast.add', {
          severity: 'error',
          summary: `hey, we've noticed a connection issue. If the problem persists, contact us at +1 (800) 288-9192 or sales@arcadian.ai. We're here to help!`,
          life: 5000
        })
      } finally {
        this.loading.value = false
      }
    }
  }

  protected isNewState(eventType: AnalyticEventTypes, index = '0') {
    return (
      !this.config.enabled ||
      this.config.activeEventType !== eventType ||
      this.config.activeIndex !== index
    )
  }

  protected isL1Level() {
    return this.core.playback.timelineSize <= PeriodType.quarter
  }

  protected getAnalyticIndex(eventType: AnalyticEventTypes, index = '0') {
    const analyticIndex = this.core.analyticManager2.analytics.findIndex((a) =>
      a.isSameIdentity({
        index,
        type: eventType
      })
    )
    if (analyticIndex >= 0) {
      return analyticIndex
    } else throw new Error('not found!')
  }

  protected updateConfig(eventType: AnalyticEventTypes, index = '0') {
    this.config.enabled = true
    this.config.activeEventType = eventType
    this.config.activeIndex = index
    this.latestConfig = { ...this.config }
  }

  protected resetConfig() {
    this.latestConfig = { ...this.config }
    this.config.enabled = false
    this.config.activeEventType = AnalyticEventTypes.None
    this.config.activeIndex = ''
  }

  protected activateTimelineL1() {
    if (this.core.timeline) {
      this.core.timeline.setCanvasRenderSize(
        this.core.timeline.renderWidth,
        timelinePositionConst[this.core.size.type].renderHeightExtended
      )
    }
  }

  protected disableTimeline() {
    if (this.core.timeline) {
      this.core.timeline.setCanvasRenderSize(
        this.core.timeline.renderWidth,
        timelinePositionConst[this.core.size.type].renderHeight
      )
    }
  }

  protected resetInterval() {
    clearInterval(this.intervalHandle)
    this.intervalHandle = setInterval(
      this.core.analyticManager2.onSoftSeek.bind(this.core.analyticManager2),
      60_000
    )
  }

  protected checkAudio(eventType: AnalyticEventTypes) {
    if (eventType === AnalyticEventTypes.HwAudio) {
      this.core.audioSetting.volume = 100
    }
  }

  protected resetActivePeriod() {
    this.activePeriod.value = undefined
  }

  protected async jumpToLatestEvent(eventType: AnalyticEventTypes, index = '0') {
    if (this.core.playback.isLive || this.core.mode.value === FunctionMode.liveMode) {
      const latestEvent = await this.core.apis.latestEventAdvance(eventType, index)
      if (latestEvent) {
        const startEvent = latestEvent.find(([time, mode]) => mode === 1)
        if (startEvent) {
          this.core.mode.value = FunctionMode.historyMode
          this.core.capture.archive(this.core.playback.lastFrameDate)
          this.core.decoderQueue.disable()
          const controller = this.core.timeline || this.core.capture
          controller.seek(startEvent[0])
          setTimeout(() => {
            this.core.capture.speed(1)
          }, 500)
        }
      }
    }
  }
}
