import type { operationHours, ScheduleRoutineDto } from '@/lib/api'
import { type ScheduleFilterDto, WeekDaysEnum } from '@/lib/api'

export enum ScheduleType {
  all = 'all',
  sw = 'sw',
  hw = 'hw'
}

export function witchScheduleType(
  data: ScheduleFilterDto[],
  map: Record<ScheduleType, ScheduleFilterDto[]>
): ScheduleType {
  for (const [schedule, eventTypes] of Object.entries(map)) {
    // Check if all eventTypes in the schedule's list are present in the data
    if (
      eventTypes.every((eventType) =>
        data.some((entity) => entity.eventType === eventType.eventType)
      )
    ) {
      return schedule as ScheduleType
    }
  }
  return ScheduleType.all
}
export function makeDefaultMainScheduleOperationHours(): operationHours[] {
  const fromDate = new Date()
  fromDate.setHours(9)
  fromDate.setMinutes(0)
  fromDate.setSeconds(0)

  const toDate = new Date()
  toDate.setHours(17)
  toDate.setMinutes(0)
  toDate.setSeconds(0)

  const operationHours: operationHours[] = []
  operationHours.push({
    close: true,
    dayName: 0,
    from: undefined,
    to: undefined
  })
  for (let i = 1; i < 6; i++) {
    operationHours.push({
      close: false,
      dayName: i,
      from: fromDate,
      to: toDate
    })
  }
  operationHours.push({
    close: true,
    dayName: 6,
    from: undefined,
    to: undefined
  })
  return operationHours
}

export function makeDefaultCriticalScheduleOperationHours(): operationHours[] {
  const operationHours: operationHours[] = []
  for (let i = 0; i < 7; i++) {
    operationHours.push({
      close: true,
      dayName: i,
      from: undefined,
      to: undefined
    })
  }
  return operationHours
}

const dayAmount = 24 * 60
const timezoneOffset = getDiffTimezoneForUTC()
function getDiffTimezoneForUTC() {
  const now = new Date()
  const localOffset = now.getTimezoneOffset()
  return -localOffset
}

function normalizeDayIndex(i: number) {
  return ((i % 7) + 7) % 7
}

function createWeekDayPoints() {
  return Array.from({ length: 9 }, (v, i) => (i - 1) * dayAmount)
}

function makeTimeline(ohs: Array<operationHours>) {
  const timeline: Array<[number, number]> = []
  for (const oh of ohs) {
    const { close, dayName } = oh
    let { from, to } = oh
    if (!close && from && to) {
      from = new Date(from)
      to = new Date(to)
      const fromAmount =
        dayName * dayAmount + from.getHours() * 60 + from.getMinutes() - timezoneOffset
      const toAmount = dayName * dayAmount + to.getHours() * 60 + to.getMinutes() - timezoneOffset
      timeline.push([fromAmount, toAmount])
    }
  }
  timeline.sort((a, b) => a[0] - b[0])
  return timeline
}

function createEmptyTimelineMap(): Record<WeekDaysEnum, Array<[number, number]>> {
  return {
    [WeekDaysEnum.Sunday]: [],
    [WeekDaysEnum.Monday]: [],
    [WeekDaysEnum.Tuesday]: [],
    [WeekDaysEnum.Wednesday]: [],
    [WeekDaysEnum.Thursday]: [],
    [WeekDaysEnum.Friday]: [],
    [WeekDaysEnum.Saturday]: []
  }
}

function createTimelineMap(timeline: Array<[number, number]>) {
  const daysCheckpoints = createWeekDayPoints()
  const timelineMap = createEmptyTimelineMap()
  for (let day = 0; day < 8; day++) {
    const dayStart = daysCheckpoints[day]
    const dayEnd = daysCheckpoints[day + 1]
    const dayIndex = normalizeDayIndex(dayStart / dayAmount)
    for (const [periodStart, periodEnd] of timeline) {
      if (periodStart >= dayStart && periodStart <= dayEnd) {
        const normalizedPeriodStart = periodStart - dayStart
        const normalizedPeriodEnd = periodEnd - dayStart
        if (periodEnd <= dayEnd) {
          timelineMap[dayIndex].push([normalizedPeriodStart, normalizedPeriodEnd])
        } else {
          timelineMap[dayIndex].push([normalizedPeriodStart, dayEnd - dayStart])
          timelineMap[normalizeDayIndex(dayIndex + 1)].push([
            dayEnd - dayStart - dayAmount,
            normalizedPeriodEnd - dayAmount
          ])
        }
      }
    }
  }
  return timelineMap
}

function setWeekDay(weekDay: WeekDaysEnum, date = new Date()) {
  date.setUTCDate(date.getUTCDate() + weekDay - date.getUTCDay())
  date.setUTCHours(0)
  date.setUTCMinutes(0)
  date.setUTCSeconds(0)
  date.setUTCMilliseconds(0)
  return date
}

function makeScheduleRoutineDto(
  timelineMap: Record<WeekDaysEnum, Array<[number, number]>>
): ScheduleRoutineDto[] {
  const result: ScheduleRoutineDto[] = []
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    const srd: ScheduleRoutineDto = {
      date: setWeekDay(dayIndex).toISOString(),
      periods: []
    }
    const timeline = timelineMap[dayIndex]
    timeline.sort((a, b) => a[0] - b[0])
    let startTimeSecond = 0
    let endTimeSecond = 0
    for (const period of timeline) {
      if (period[0] === dayAmount) {
        period[0] -= 1
        startTimeSecond = 59
      }
      if (period[1] === dayAmount) {
        period[1] -= 1
        endTimeSecond = 59
      }
      srd.periods.push({
        startTime: {
          hour: Math.floor(period[0] / 60),
          minute: Math.floor(period[0] % 60),
          second: startTimeSecond
        },
        endTime: {
          hour: Math.floor(period[1] / 60),
          minute: Math.floor(period[1] % 60),
          second: endTimeSecond
        }
      })
    }
    result.push(srd)
  }
  return result
}

export function convertOperationHoursToRoutines(ohs: Array<operationHours>): ScheduleRoutineDto[] {
  const timeline = makeTimeline(ohs)
  const timelineMap = createTimelineMap(timeline)
  return makeScheduleRoutineDto(timelineMap)
}
